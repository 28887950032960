<template>
  <v-row
    no-gutters
    align="center"
    justify="start"
    class="certificate py-2 px-0 default--text"
  >
    <!-- <v-col cols="1">
      <v-img v-if="src" :src="src" :alt="`Coupon ${name}`" class="rounded-sm" />
      <div
        v-else
        class="certificate-card d-flex flex-column justify-space-around align-center"
        @click.prevent.stop="handleClick"
        outlined
      >
        <span>%</span>
      </div>
    </v-col> -->
    <v-col cols="12" sm="8" class="default--text">
      <div class="d-flex align-center">
        <div class="giftCodeLogo pa-3 mr-2">
          <v-icon
            v-if="
              !giftCertificate.userGiftCertificates ||
                !giftCertificate.userGiftCertificates[0].amount
            "
            color="rgb(74, 74, 73)"
            >$gift</v-icon
          >
          <span
            v-if="
              giftCertificate.userGiftCertificates &&
                giftCertificate.userGiftCertificates[0].amount > 0
            "
            class="mx-1"
          >
            {{ giftCertificate.userGiftCertificates[0].amount }}€
          </span>
        </div>
        <div>
          <div class="text-caption">
            {{
              `${$t("checkout.expires")} ${$dayjs(
                giftCertificate.endDate
              ).format("D MMMM YYYY")}`
            }}
          </div>
          <div class="text-body-2 font-weight-bold" v-html="description"></div>
        </div>
      </div>
    </v-col>
    <v-col cols="12" sm="4" class="gift-action d-flex justify-end">
      <v-btn
        v-if="
          userGiftCertificateId != null && giftCertificate.cartId != cart.cartId
        "
        depressed
        outlined
        color="primary"
        min-width="80"
        @click="remove(userGiftCertificateId)"
      >
        {{ $t("checkout.giftCodeRemove") }}
      </v-btn>
      <v-btn v-else-if="true" depressed color="primary" @click="add">
        {{ $t("checkout.giftCodeUse") }}
      </v-btn>
    </v-col>
    <!-- DESCRIPTION FOR MOBILE DEVICES -->
    <!-- <v-col cols="10" class="d-flex d-sm-none offset-2" v-html="description">
    </v-col> -->
  </v-row>
</template>
<style lang="scss" scoped>
.giftCodeLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  min-width: 55px;
  height: 55px;
  background-color: var(--v-grey-lighten2);
  font-size: 20px;
  font-weight: 600;
  border-radius: 8px;
}
.gift-action {
  .v-btn {
    max-width: 120px;
  }
}
</style>
<script>
import giftCertificate from "@/components/gift/giftCertificate";

export default {
  name: "GiftRow",
  mixins: [giftCertificate]
};
</script>
