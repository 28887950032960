var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"certificate py-2 px-0 default--text",attrs:{"no-gutters":"","align":"center","justify":"start"}},[_c('v-col',{staticClass:"default--text",attrs:{"cols":"12","sm":"8"}},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"giftCodeLogo pa-3 mr-2"},[(
            !_vm.giftCertificate.userGiftCertificates ||
              !_vm.giftCertificate.userGiftCertificates[0].amount
          )?_c('v-icon',{attrs:{"color":"rgb(74, 74, 73)"}},[_vm._v("$gift")]):_vm._e(),(
            _vm.giftCertificate.userGiftCertificates &&
              _vm.giftCertificate.userGiftCertificates[0].amount > 0
          )?_c('span',{staticClass:"mx-1"},[_vm._v(" "+_vm._s(_vm.giftCertificate.userGiftCertificates[0].amount)+"€ ")]):_vm._e()],1),_c('div',[_c('div',{staticClass:"text-caption"},[_vm._v(" "+_vm._s(`${_vm.$t("checkout.expires")} ${_vm.$dayjs( _vm.giftCertificate.endDate ).format("D MMMM YYYY")}`)+" ")]),_c('div',{staticClass:"text-body-2 font-weight-bold",domProps:{"innerHTML":_vm._s(_vm.description)}})])])]),_c('v-col',{staticClass:"gift-action d-flex justify-end",attrs:{"cols":"12","sm":"4"}},[(
        _vm.userGiftCertificateId != null && _vm.giftCertificate.cartId != _vm.cart.cartId
      )?_c('v-btn',{attrs:{"depressed":"","outlined":"","color":"primary","min-width":"80"},on:{"click":function($event){return _vm.remove(_vm.userGiftCertificateId)}}},[_vm._v(" "+_vm._s(_vm.$t("checkout.giftCodeRemove"))+" ")]):(true)?_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.add}},[_vm._v(" "+_vm._s(_vm.$t("checkout.giftCodeUse"))+" ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }